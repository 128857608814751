import React, { FC, useEffect } from "react";

import { observer } from "mobx-react";

import { Box, Heading, Text, } from "grommet";
import { Loader } from "components/Common/Loader";

import { FilterServers } from "./components/FilterServers";
import { Package } from "grommet-icons";
import { hardwareStore } from "store/HardwareStore";

export const HardwareComponent: FC = observer (() => {

   useEffect (() => {
      hardwareStore.getHardwares ()
   }, [])

   //console.log(data)

   if (hardwareStore.loading) return <Loader />;
   else if (hardwareStore.hardwares.length == 0) {

      return (
         <Box
            align   = "center"
            justify = "center"
            margin  = "xlarge">
            <Package />
            <Text>Нет оборудования</Text>
         </Box>
      )
   } 
   else {

      return (
         <>
            <Box pad = "large">
               <Heading
                  style = { { margin: "0" } }
               >
                  Оборудование
               </Heading>
            </Box>

            <Box
               pad = { {
                  vertical  : "none" ,
                  horizontal: "large", 
               } }
            >
               <FilterServers />
            </Box>
         </>
      )
   }
  
});