import React from "react";

import { editHardwareDescription } from "api/hardware";
import { EditableBox } from "components/UI/EditableBox/EditableBox";
import { hardwareStore } from "store/HardwareStore";

export const ResultDescription = (props: {hardware, description}) => {

   const saveValue = ( value ) => {

      editHardwareDescription (props.hardware, value != '' ? value : props.description)
      .then(() => hardwareStore.getHardwares ());
   }

   return (
      <EditableBox
         key = { props.hardware }
         value     = { props.description }
         saveValue = { saveValue }
      />
   )
} 