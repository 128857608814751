import React from "react";

import { editHardwareName } from "api/hardware";
import { EditableBox      } from "components/UI/EditableBox/EditableBox";
import { hardwareStore } from "store/HardwareStore";

export const ResultName = (props: {hardware, name}) => {

   const saveValue = ( value ) => {

      editHardwareName (props.hardware, value != '' ? value : props.name)
      .then(() => hardwareStore.getHardwares ());
   }

   return (
      <EditableBox
         key       = { props.hardware }
         value     = { props.name     }
         saveValue = { saveValue      }
      />
   )
} 